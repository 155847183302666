import React from 'react';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import ContactForm from '../components/ContactForm/ContactForm';
import FloatingBox from '../components/FloatingBox';
import InfoCarousel from '../components/InfoCarousel';
import IndexStyled from '../components/page-styles/index-styles';
import IntroBox from '../components/IntroBox';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = ({ path, size }) => (
    <Layout path={path}>
        <IndexStyled>
            <SEO title="Saratoga Rack Marketing Home Page" />
            <section className="homepage-hero">
                <motion.h1
                    animate={{ y: -150, opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeOut', delay: 0.4 }}
                >
                    Your ONE Source Supplier Network
                </motion.h1>
            </section>
            <section>
                <div className="intro-box-position">
                    <IntroBox size={size}></IntroBox>
                </div>
            </section>
            <section className="connected-network">
                <FloatingBox
                    headerText="Connected Network"
                    bodyText="Our extensive carrier network and partner fleets give us the ability to move fuel where and when you need it. Our team of experienced professionals is standing by to help create your unique fuel solution."
                ></FloatingBox>
            </section>
            <section className="why-saratoga">
                <InfoCarousel header="Why Saratoga" />
            </section>
            <section className="questions">
                <FloatingBox
                    headerText="Have Questions?"
                    bodyText="Our experienced team of responsive fuel professionals is standing by to answer all your questions."
                    linkLocation="/contact"
                    linkText="Contact Us"
                ></FloatingBox>
            </section>
            <ContactForm id="contact" />
        </IndexStyled>
    </Layout>
);

IndexPage.propTypes = {
    size: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default withSize()(IndexPage);
