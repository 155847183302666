import React from 'react';
import styled from 'styled-components';

import Heading from './Heading';

import defIcon from '../images/defIcon.svg';
import rackIcon from '../images/rackIcon.svg';
import truckIcon from '../images/truckIcon.svg';
import introInfo from '../images/info-graph.png';

const BoxStyled = styled.div`
    box-shadow: rgba(228, 226, 219, 0.4) 10px 10px;
    background-color: #f7f7f5;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
        padding: 50px 100px;
    }
    @media screen and (min-width: 1440px) {
        flex-direction: row;
        align-items: flex-start;
        padding: 65px;
    }
    .intro-copy {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: 1440px) {
            align-items: flex-start;
            padding-right: 75px;
            width: 45%;
        }
        h2 {
            align-self: flex-start;
        }

        p {
            font-family: clarendon-text-pro, serif;
        }
    }
    p,
    h2 {
        text-align: left;
    }

    p {
        font-size: 18px;
        line-height: 36px;
    }

    .intro-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .intro-heading {
        font-family: sofia-pro, sans-serif;
        font-weight: 200;
        font-size: 32px;
        margin-top: 15px;
        text-align: center;
    }

    .intro-subheading {
        font-family: sofia-pro, sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-top: 20px;
        text-align: center;
        color: #266fc5;
    }

    .intro-infograph {
        width: 90%;
        @media screen and (min-width: 1440px) {
            width: 50%;
        }
    }
`;

export default function IntroBox({ size }) {
    return (
        <BoxStyled>
            <div className="intro-copy">
                <Heading
                    text="Fueling your business needs "
                    headingType="h2"
                ></Heading>
                <p>
                    Saratoga is your TRUSTED fuel supplier. With decades of
                    combined experience in the fuel industry, our team
                    understands the importance of a reliable fuel partner, and
                    our network backs it up. We offer the fuel supply your
                    organization needs - from gasoline and diesel, to DEF,
                    ethanol and other additives. We sell our product directly at
                    the terminal or deliver directly to your door as part of
                    your custom fuel solution. With more than 300 million
                    gallons of storage (and counting) at any given time, we are
                    your dependable supply partner, no matter your fuel needs.
                </p>
            </div>
            {size.width < 1024 ? (
                <>
                    <div className="intro-icon">
                        <img src={rackIcon} alt="rack"></img>
                        <p className="intro-heading">
                            Rack
                            <br />
                            Marketing
                        </p>
                        <p className="intro-subheading">
                            NATIONWIDE SPOT SALES
                        </p>
                    </div>
                    <div className="intro-icon">
                        <img src={truckIcon} alt="trucking"></img>
                        <p className="intro-heading">
                            Wholesale
                            <br />
                            Delivered
                        </p>
                        <p className="intro-subheading">FUEL ON DEMAND</p>
                    </div>
                    <div className="intro-icon">
                        <img src={defIcon} alt="def"></img>
                        <p className="intro-heading">DEF</p>
                        <p className="intro-subheading">
                            FUELING MODERN FLEETS
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <img
                        className="intro-infograph"
                        src={introInfo}
                        alt="infograph"
                    ></img>
                </>
            )}
        </BoxStyled>
    );
}
