import styled from 'styled-components';
import HeroImage from '../../images/hero-with-divider.png';
import backgroundDesktop from '../../images/bg-hires-large.jpg';
import backgroundTablet from '../../images/map-768.jpg';
import backgroundMobile from '../../images/page-bg-mobile.png';
import questionsBg from '../../images/questions-bg.jpg';
import HeroImagex2 from '../../images/hero-with-divider-lg.png';

const IndexStyled = styled.div`
    background: url(${backgroundMobile}) no-repeat;
    background-size: contain;
    background-position: 0 1600px;

    @media screen and (min-width: 450px) {
        background: url(${backgroundTablet}) no-repeat;
        background-position: 0 1200px;
        background-size: contain;
    }

    @media screen and (min-width: 768px) {
        background: url(${backgroundDesktop}) no-repeat;
        background-position: 0 900px;
        background-size: contain;
    }
    section {
        width: 100%;
    }

    .intro-box-position {
        transform: translateY(-250px);
        @media screen and (min-width: 1440px) {
            transform: translateY(-400px);
        }
    }
    .homepage-hero {
        background: url(${HeroImage}) no-repeat center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 757px;
        @media screen and (min-width: 1440px) {
            background: url(${HeroImagex2}) no-repeat center;
            height: 1140px;
            background-size: cover;
            width: 100vw;
        }

        h1 {
            max-width: 450px;
            font: 900 60px sofia-pro, sans-serif;
            line-height: 74px;
            letter-spacing: -2.18px;
            text-align: center;
            color: #fff;

            @media screen and (max-width: 480px) {
                font-size: 48px;
                letter-spacing: -1.74px;
                line-height: 50px;
            }
        }
    }
    .connected-network {
        display: flex;
        justify-content: center;
        div {
            margin-top: 0px;
        }

        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
        }
    }

    .why-saratoga {
        margin-top: 260px;
        z-index: 11;
        position: relative;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 1024px) {
            display: block;
        }
    }

    .questions {
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
        }
        background: url(${questionsBg}) no-repeat center;
        background-size: cover;
        width: 100vw;
        /* height: 675px; */
        transform: translateY(-50px);
        z-index: 10;
        padding-bottom: 100px;
    }
`;

export default IndexStyled;
