import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Heading from './Heading';

const BoxStyled = styled.div`
    box-shadow: rgba(228, 226, 219, 0.4) 10px 10px;
    background-color: #f7f7f5;
    display: flex;
    flex-direction: column;
    padding: 55px 25px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    margin-top: 200px;
    /* height: 370px; */
    @media screen and (min-width: 1120px) {
        padding: 75px 50px;
        width: 40%;
        margin: 0;
        margin-top: 200px;
        /* height: 330px; */

        margin-right: 7.5%;
    }

    p {
        font-size: 18px;
        line-height: 36px;
    }

    h3 {
        padding: 0;
        margin: 0;
    }

    .button-position {
        align-self: flex-start;
    }
`;

export default function FloatingBox({ headerText, bodyText, linkLocation }) {
    return (
        <BoxStyled>
            <Heading text={headerText} headingType="h3"></Heading>
            <p>{bodyText}</p>
            <div className="button-position">
                {linkLocation ? (
                    <Button
                        buttonText="Contact Us"
                        link={linkLocation}
                        buttonType="primary"
                        isExternal="false"
                    ></Button>
                ) : (
                    <></>
                )}
            </div>
        </BoxStyled>
    );
}
