import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const CarouselStyled = styled.div`
    ul {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        margin: 0;

        -ms-overflow-style: none;

        @media screen and (min-width: 1440px) {
            justify-content: center;
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    ul::-webkit-scrollbar {
        display: none;
    }

    h2 {
        text-align: center;
        border-bottom: 4px solid #be3a34;
        padding-bottom: 15px;
        width: 190px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'sofia-pro', sans-serif;
        font-size: 28px;
    }

    .fact-container {
        color: #fff;
        width: 312px;
        min-width: 312px;
        height: 564px;
        background-color: #be3a34;
        display: inline-block;
        box-sizing: border-box;
        align-self: center;
        box-sizing: border-box;
        margin-left: 9px;
        margin-right: 9px;
        margin-top: 5px;
        position: relative;
        overflow: hidden;
        transition: all 0.3s cubic-bezier(0.48, 1.28, 0.61, 1.04);

        .fact-header {
            font-family: 'sofia-pro', sans-serif;
            font-weight: 200;
            font-size: 48px;
            margin: 0;
        }

        .fact-content {
            margin-top: 250px;
            width: 100%;
            width: 250px;
            margin-left: 35px;
        }

        .fact-body {
            font-family: sofia-pro, sans-serif;
            font-size: 18px;
        }

        .link-position {
            position: absolute;
            bottom: 40px;
            border-top: 4px solid white;
            left: 35px;
            width: 250px;
            padding-top: 20px;
            transition: bottom 0.3s ease-in;
        }

        .fact-link {
            color: white;
            text-decoration: none;
            font-family: sofia-pro, sans-serif;
            font-size: 14px;
            padding-bottom: 40px;
            padding-top: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    @media screen and (min-width: 1400px) {
        .fact-container .link-position {
            bottom: -100px;
        }

        li.fact-container:hover {
            width: 330px;
            min-width: 330px;
            height: 600px;
            margin: 0;

            .link-position {
                bottom: 40px;
            }
        }
    }
`;

function InfoCarousel({ header, facts }) {
    return (
        <CarouselStyled>
            <h2>{header}</h2>
            <ul>
                {facts.map(i => (
                    <li className="fact-container" key={i.fact}>
                        <div className="fact-content">
                            <p className="fact-header">{i.fact}</p>
                            <p className="fact-body">{i.body}</p>
                        </div>
                        <div className="link-position">
                            <Link className="fact-link" to={i.action}>
                                {i.link}
                            </Link>
                        </div>
                    </li>
                ))}
            </ul>
        </CarouselStyled>
    );
}

InfoCarousel.propTypes = {
    header: PropTypes.string.isRequired,
    facts: PropTypes.arrayOf(PropTypes.object),
};

InfoCarousel.defaultProps = {
    facts: [
        {
            fact: '300m+',
            body:
                'Gallons of product in storage to ensure you have fuel where and when you need it',
            action: '/services',
            link: 'EXPLORE OUR SERVICES',
        },
        {
            fact: '24/7',
            body:
                'Personal contact with customer service. You need fuel around the clock, so we make sure you can reach us at any time',
            action: '/contact',
            link: 'GET IN TOUCH',
        },
        {
            fact: '48 and 6',
            body:
                'American States and Canadian Provinces within our network, allowing us to accommodate almost any service area',
            action: '/about',
            link: 'ABOUT SARATOGA',
        },
    ],
};
export default InfoCarousel;
